import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OneOf, OneOfData, SurrenderDetails, SurrenderValues } from '../full-surrender.constant';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../../../../services/common.service';
import { PartySearchFactory } from '../../../../factories/partySearchFactory';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../format-datepicker';

@Component({
  selector: 'app-full-surrender-surrender',
  templateUrl: './full-surrender-surrender.component.html',
  styleUrls: ['./full-surrender-surrender.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class FullSurrenderSurrenderComponent implements OnInit {

    @Input() withdrawlSurrenderType: any;
    @Input() surrenderDetails: SurrenderDetails;
    @Input() isLife: boolean;
    @Output() passDistributionData: EventEmitter<any> = new EventEmitter<any>();

    alertMsg = [];
    surrenderForm: FormGroup;

    responseOptions: any;
    requiredFields: any;

    statePctOrBasis: any = [];

    federal_withholding_override: boolean = false;
    state_withholding_override: boolean = false;

    client_recommend_or_authorized: boolean = false;
    agent_recommend_or_authorized: boolean = false;

    enhanced_system: boolean = false;
    isUniversalProduct: boolean = false;
    isTraditionalProduct: boolean = false;

    ssn_facta_verify: any;

    constructor(
        private formBuilder: FormBuilder,
        private partySearchFactory: PartySearchFactory
    ) { }

    ngOnInit() {

        this.surrenderFormInit();
        
        this.surrenderForm.get('federal_withholding_amount').disable();
        this.surrenderForm.get('federal_withholding_percentage').disable();

        this.surrenderForm.get('state_withholding_amount').disable();
        this.surrenderForm.get('state_withholding_percentage').disable();
    }


    surrenderFormInit() {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')

        this.surrenderForm = this.formBuilder.group({
            effective_date: [false, [Validators.required]],

            statePctOrBasis: [''],

            no_federal_withholding: [false],
            federal_withholding_override: [false],
            federal_withholding_amount: [currencyValue],
            federal_withholding_percentage: [percentValue],

            no_state_withholding: [false],
            state_withholding_override: [false],
            state_withholding_amount: [currencyValue],
            state_withholding_percentage: [percentValue]
        });
        const authorizedRole = OcInfraModule.AppInjector.get(CommonService).authorizedRole;

        if(this.isLife) {
            this.isUniversalProduct = this.partySearchFactory.isUL(); 
            this.isTraditionalProduct = this.partySearchFactory.isTermProduct();

            this.surrenderForm.addControl("enhanced_system", new FormControl(false, Validators.requiredTrue));
            this.enhanced_system = true;

            this.surrenderForm.addControl("agent_recommend_or_authorized", new FormControl(false, []));
            this.agent_recommend_or_authorized = true;

            this.surrenderForm.addControl("ssn_facta_verify", new FormControl(''));
        }

    }


    get federal_withholding_amount() {
        return this.surrenderForm.get('federal_withholding_amount').value;
    }

    set federal_withholding_amount(val) {
        this.surrenderForm.get('federal_withholding_amount').setValue(val);
    }

    get federal_withholding_percentage() {
        return this.surrenderForm.get('federal_withholding_percentage').value;
    }

    set federal_withholding_percentage(val) {
        this.surrenderForm.get('federal_withholding_percentage').setValue(val);
    }

    get state_withholding_amount() {
        return this.surrenderForm.get('state_withholding_amount').value;
    }

    set state_withholding_amount(val) {
        this.surrenderForm.get('state_withholding_amount').setValue(val);
    }

    get state_withholding_percentage() {
        return this.surrenderForm.get('state_withholding_percentage').value;
    }

    set state_withholding_percentage(val) {
        this.surrenderForm.get('state_withholding_percentage').setValue(val);
    }



    disabled = (inputElement: ElementRef, flag: boolean) => {
        if (flag) {
            inputElement.nativeElement.setAttribute('disabled', 'true');
        } else {
            inputElement.nativeElement.removeAttribute('disabled');
        }
    };

    // integrates the API response 
    getSurrenderDetails() {
        let response = this.surrenderDetails;
        this.responseOptions = response._options;
        this.requiredFields = response._options && response._options.required || [];

        this.loadPermanentBasics();
         // Filter ssn_facta_verify from drools response
        if (this.responseOptions && this.responseOptions.properties.ssn_facta_verify && this.responseOptions.properties.ssn_facta_verify.oneOf) {
            for (let i = 0; i < this.responseOptions.properties.ssn_facta_verify.oneOf.length; i++) {
            if (response.ssn_facta_verify == this.responseOptions.properties.ssn_facta_verify.oneOf[i].enum[0]) {
                this.surrenderForm.get('ssn_facta_verify').setValue(this.responseOptions.properties.ssn_facta_verify.oneOf[i].title);
            }
            }
        }
        if (this.surrenderDetails.effective_date != undefined) {
            this.surrenderForm.get('effective_date').setValue(new Date(this.surrenderDetails.effective_date));
            this.surrenderForm.get('effective_date').disable();
        }

        if (this.surrenderDetails.no_federal_withholding != undefined) {
            let federalWithHoldValue = this.surrenderDetails.no_federal_withholding == 'N' ? false : true;
            this.surrenderForm.get('no_federal_withholding').setValue(federalWithHoldValue);
        }

        if (this.surrenderDetails.federal_withholding_amount != undefined) {
            if (this.isLife) {
                let federal_withholding_amount = this.formatCurrency('', 'blur', this.surrenderDetails.federal_withholding_amount)
                this.federal_withholding_amount = federal_withholding_amount;
            }
        }
        if (this.surrenderDetails.federal_withholding_percentage != undefined) {
            if (this.isLife) {
                let federal_withholding_percentage = this.formatPercent('', 'blur', this.surrenderDetails.federal_withholding_percentage)
                this.federal_withholding_percentage = federal_withholding_percentage
            }
        }

        if (this.surrenderDetails.no_state_withholding != undefined) {
            let stateWithHoldValue = this.surrenderDetails.no_state_withholding == 'N' ? false : true;
            this.surrenderForm.get('no_state_withholding').setValue(stateWithHoldValue);
        }

        if (this.surrenderDetails.state_withholding_amount != undefined) {
            if (this.isLife) {
                let state_withholding_amount = this.formatCurrency('', 'blur', this.surrenderDetails.state_withholding_amount);
                this.state_withholding_amount = state_withholding_amount;
            }
        }
        if (this.surrenderDetails.state_withholding_percentage != undefined) {
            if (this.isLife) {
                let state_withholding_percentage = this.formatPercent('', 'blur', this.surrenderDetails.state_withholding_percentage);
                this.state_withholding_percentage = state_withholding_percentage;
            }
        }

        const authorizedRole = OcInfraModule.AppInjector.get(CommonService).authorizedRole;

        
        if(!this.isLife) {

            if (authorizedRole === 'Agent' || authorizedRole === 'BOA Back Office Assistant (Advisor Assistant)') {
                this.surrenderForm.addControl("client_recommend_or_authorized", new FormControl(false, Validators.requiredTrue));
                this.client_recommend_or_authorized = true;
             }
    
            if ((authorizedRole !== 'Agent' && authorizedRole !== 'BOA Back Office Assistant (Advisor Assistant)') && (authorizedRole == 'Owner' && this.surrenderDetails.owner_state == 'FL')) {
              this.surrenderForm.addControl("agent_recommend_or_authorized", new FormControl(false, Validators.requiredTrue));
              this.agent_recommend_or_authorized = true;
             } else {
                this.agent_recommend_or_authorized = false;
            }
        }
    }

    loadPermanentBasics = () => {
        let statePctOrBasis = this.getNested(this.surrenderDetails._options.properties, 'statePctOrBasis');
        if (statePctOrBasis && statePctOrBasis.oneOf.length > 0) {
            this.statePctOrBasis = statePctOrBasis.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    isStepComplete = () => {
        return this.surrenderForm.valid;
    }


    noFederalEvent = ($event) => {
        if ($event.checked) {
            this.surrenderForm.get('federal_withholding_override').disable();
            // this.surrenderForm.get('federal_withholding_amount').disable();
            // this.surrenderForm.get('federal_withholding_percentage').disable();
        } else {
            this.surrenderForm.get('federal_withholding_override').enable();
            // this.surrenderForm.get('federal_withholding_amount').enable();
            // this.surrenderForm.get('federal_withholding_percentage').enable();
        }
    };

    noStateEvent = ($event) => {
        if ($event.checked) {
            this.surrenderForm.get('state_withholding_override').disable();
            // this.surrenderForm.get('state_withholding_amount').disable();
            // this.surrenderForm.get('state_withholding_percentage').disable();
        } else {
            this.surrenderForm.get('state_withholding_override').enable();
            // this.surrenderForm.get('state_withholding_amount').enable();
            // this.surrenderForm.get('state_withholding_percentage').enable();
        }
    };

    federalOverrideEvent = ($event: any) => {
        if ($event.checked) {
            if(this.isTraditionalProduct){
                this.surrenderForm.get('federal_withholding_amount').enable();
                this.surrenderForm.get('federal_withholding_percentage').disable();
                this.surrenderForm.get('no_federal_withholding').disable();
            } else {
            this.surrenderForm.get('federal_withholding_amount').enable();
            this.surrenderForm.get('federal_withholding_percentage').enable();
            this.surrenderForm.get('no_federal_withholding').disable();
            }

        } else if(!this.isLife && !($event.checked)){
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
            this.surrenderForm.get('no_federal_withholding').enable();
            let federalWithHoldAValue = this.formatCurrency('', 'blur', '0.00')
            this.surrenderForm.get('federal_withholding_amount').setValue(federalWithHoldAValue);
            let federalWithHoldValue = this.formatPercent('', 'blur',  '0.00')
            this.surrenderForm.get('federal_withholding_percentage').setValue(federalWithHoldValue);
            this.passDistributionData.emit(federalWithHoldValue);
        } else{
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
            this.surrenderForm.get('no_federal_withholding').enable();
        }
    }

    stateOverrideEvent = ($event: any) => {
        if ($event.checked) {
            if(this.isTraditionalProduct){
                this.surrenderForm.get('state_withholding_amount').enable();
                this.surrenderForm.get('state_withholding_percentage').disable();
                this.surrenderForm.get('no_state_withholding').disable();
                this.surrenderForm.get('statePctOrBasis').setValue('A');
            } else{
            this.surrenderForm.get('state_withholding_amount').enable();
            this.surrenderForm.get('state_withholding_percentage').enable();
            this.surrenderForm.get('no_state_withholding').disable();
            this.surrenderForm.get('statePctOrBasis').setValue('A');
            }
        } else if(!this.isLife){
            this.surrenderForm.get('state_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('no_state_withholding').enable();
            let stateWithHoldAValue = this.formatCurrency('', 'blur',  '0.00')
            this.surrenderForm.get('state_withholding_amount').setValue(stateWithHoldAValue);
            let stateWithHoldValue = this.formatPercent('', 'blur', '0.00')
            this.surrenderForm.get('state_withholding_percentage').setValue(stateWithHoldValue);
            this.surrenderForm.get('statePctOrBasis').setValue('');
        } else{
            this.surrenderForm.get('state_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('no_state_withholding').enable();
        }
    }


    federalWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.federal_withholding_amount = this.formatCurrency('', 'blur', this.federal_withholding_amount);
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
        }

        if (type == 'PERCENT') {
            this.federal_withholding_percentage = this.formatPercent('', 'blur', this.federal_withholding_percentage);
            this.passDistributionData.emit(this.federal_withholding_percentage);
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.percentageCheck(this.federal_withholding_percentage);
        }
    };

    stateWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.state_withholding_amount = this.formatCurrency('', 'blur', this.state_withholding_amount);
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('statePctOrBasis').setValue('');
        }

        if (type == 'PERCENT') {
            this.state_withholding_percentage = this.formatPercent('', 'blur', this.state_withholding_percentage);
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_amount').disable();
            this.percentageCheck(this.state_withholding_percentage);
        }
    };


    // To Pass the ser selected values to the master component
    getSurrenderValues = (): SurrenderValues => {

        const { federal_withholding_amount, federal_withholding_percentage, state_withholding_amount, state_withholding_percentage  } = this.surrenderForm.getRawValue();

        let surrenderValues: SurrenderValues = {
            effective_date: this.surrenderDetails.effective_date,
            withdrawal_type: this.withdrawlSurrenderType,
            transaction_type: '',            

            statePctOrBasis: this.surrenderForm.get('statePctOrBasis').value,

            no_federal_withholding: this.surrenderForm.get('no_federal_withholding').value ? 'Y' : 'N',
            federal_withholding_amount: federal_withholding_amount,
            federal_withholding_percentage: federal_withholding_percentage,
            federal_withholding_override: this.surrenderForm.get('federal_withholding_override').value,

            no_state_withholding: this.surrenderForm.get('no_state_withholding').value ? 'Y' : 'N',
            state_withholding_amount: state_withholding_amount,
            state_withholding_percentage: state_withholding_percentage,
            state_withholding_override: this.surrenderForm.get('state_withholding_override').value,
        };
        return surrenderValues;
    }

    percentageCheck(value){
        let totalWithPer = value.includes('%') ? value.slice(0,-1) : value;
        let totalInt = Math.trunc(totalWithPer);
        let finalVal = totalInt.toString();
        if(finalVal.length>2){
            let alertMsg = [];
            alertMsg.push("You can not enter more than 2 digits");
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        }
    }


    ngAfterViewInit() {
        setTimeout(() => {
            this.getSurrenderDetails();
            this.getSurrenderValues();
        }, 0);
    }

    ngOnDestroy() {
        setTimeout(() => {
            // this.activeTab$.unsubscribe();
        }, 0);
    }








    //formats the value with commas and $ allowing 2 decimal values
    formatCurrency(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        }
        if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'currency', true);
            right_side = this.formatNumber(right_side, 'currency', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = input_val.substring(0, 15);
            input_val = this.formatNumber(input_val, 'currency', true);
            input_val = "$" + input_val;
            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    // formats the value with commas and percentage allowing 1 decimal value
    formatPercent(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        } if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'percent', true);
            right_side = this.formatNumber(right_side, 'percent', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = left_side + "." + right_side + "%";
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val, 'percent', true);
            if (blur === "blur") {
                input_val += ".00";
            }
            input_val = input_val + "%";
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }


    /** AMOUNT AND PERCENT FORMATING  */
    formatNumber(n, type, flag) {
        if (flag) {
            n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
        }
        if (type == 'currency') {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }

}
