import { Component, OnInit, Input } from '@angular/core';
import { eftInformation } from './constants';
import * as _ from 'lodash';
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from "../../../app/services/common.service";

@Component({
  selector: 'withdrawl-surrender-payment',
  templateUrl: './paymentComponent.html',
  styleUrls: ['./paymentComponent.scss']
})
export class WithdrawlSurrenderPayment implements OnInit {

  @Input() withdrawlSurrenderType: any;
  @Input() policyDetails: Object;
  @Input() surrenderDetails: any;
  @Input() isLife: boolean;

  
  paymentMethod: any[];
  surrenderEftInformation = eftInformation;
  eftInformation: boolean = false;
  distributionCode: any;
  ischeck:boolean=true;
  distribution_code: any;
  isBankAccountVerified: boolean= false;
  disablePaymentMethod: boolean = false;
  bankAccount:any;
  BankAccount: any;
  selectedBankAccount: any;
  
  // Fields for form value cash
  first_name: any;
  last_name: any;
  address_line1: any;
  address_line2: any;
  address_line3: any;
  city: any;
  state: any;
  postal_code: any;
  country: any;
  
  // Fields for form value eft
  role: any
  eft_status: any
  account_type: any
  bank_account: any
  bank_name: any
  account_number: any
  transit: any
  eft_two_premium_payments_verified: any
  bank_account_verified: any

  requiredFields: any;
  surrenderPaymentMetod: any;
  responseOptions: any;

  disbursement: any;  

  withdrawToPayPremiumM: any = 'N';
  withdraw_to_pay_premium: any;
  
  disbursementIndM: any;
  disbursement_ind: any;

  eft_id: any

  ngOnInit() {
    this.getPaymentDetails();
  }

  getPaymentDetails() {
    this.responseOptions = this.surrenderDetails._options;
    if(this.surrenderDetails.eft_status){
      this.responseOptions.required.push('bank_account_verified');
    }
    this.requiredFields = this.responseOptions && this.responseOptions.required || [];

    if(this.withdrawlSurrenderType == 'DIVIDEND_WITHDRAWAL'){
    // Assign response data for default select box selection
    this.disbursementIndM = this.surrenderDetails.disbursement_ind[0]
    
    // Asign dropdown option based on response for withdraw to pay premium 
    this.withdraw_to_pay_premium = [];
    if (this.responseOptions.properties.withdraw_to_pay_premium && this.responseOptions.properties.withdraw_to_pay_premium.oneOf) {
      for (let i = 0; i < this.responseOptions.properties.withdraw_to_pay_premium.oneOf.length; i++) {       
        if (this.surrenderDetails.withdraw_to_pay_premium.includes(this.responseOptions.properties.withdraw_to_pay_premium.oneOf[i].enum[0])) {
          this.withdraw_to_pay_premium.push({ id: this.responseOptions.properties.withdraw_to_pay_premium.oneOf[i].enum[0], title: this.responseOptions.properties.withdraw_to_pay_premium.oneOf[i].title })
        }
      }
    }    

    // Asign dropdown option based on response for disbursement 
    this.disbursement_ind = [];
    if (this.responseOptions.properties.disbursement_ind && this.responseOptions.properties.disbursement_ind.oneOf) {
      for (let i = 0; i < this.responseOptions.properties.disbursement_ind.oneOf.length; i++) {       
        if (this.surrenderDetails.disbursement_ind.includes(this.responseOptions.properties.disbursement_ind.oneOf[i].enum[0])) {
          this.disbursement_ind.push({ id: this.responseOptions.properties.disbursement_ind.oneOf[i].enum[0], title: this.responseOptions.properties.disbursement_ind.oneOf[i].title })
        }
      }
    }
    this.first_name = this.surrenderDetails.first_name;
    this.last_name = this.surrenderDetails.last_name;
    this.address_line1 = this.surrenderDetails.address_line1;
    this.address_line2 = this.surrenderDetails.address_line2;
    this.address_line3 = this.surrenderDetails.address_line3;
    this.city = this.surrenderDetails.city;
    this.state = this.surrenderDetails.state;
    this.postal_code = this.surrenderDetails.postal_code.replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
    this.country = this.surrenderDetails.country;
    this.role = this.surrenderDetails.role;
    this.eft_status = this.surrenderDetails.eft_status;
    this.account_type = this.surrenderDetails.account_type_desc;
    this.bank_account = this.surrenderDetails.bank_account;
    this.bank_name = this.surrenderDetails.bank_name;
    this.account_number = this.surrenderDetails.account_number;
    this.transit = this.surrenderDetails.transit;
  }
  if(this.withdrawlSurrenderType == 'FULL_SURRENDER' || this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' || this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
    this.disbursementIndM = "C";
    (this.surrenderDetails &&  this.surrenderDetails.bank_account)? (this.BankAccount = this.surrenderDetails.bank_account[0]):null;

    //Asign dropdown option based on response for disbursement 
    this.disbursement_ind = [];
    if (this.responseOptions.properties.payment_method && this.responseOptions.properties.payment_method.oneOf) {
      for (let i = 0; i < this.responseOptions.properties.payment_method.oneOf.length; i++) {       
        if (this.surrenderDetails.payment_method.includes(this.responseOptions.properties.payment_method.oneOf[i].title)) {
          this.disbursement_ind.push({ id: this.responseOptions.properties.payment_method.oneOf[i].enum[0], title: this.responseOptions.properties.payment_method.oneOf[i].title })
        }
      }
    }

    if(this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
        this.disbursementIndM = this.surrenderDetails.disbursement_ind ? this.surrenderDetails.disbursement_ind : 'C';
    }

    this.bankAccount = [];
    if (this.surrenderDetails && this.surrenderDetails.bank_account) {
        this.bankAccount = this.sortBankAccountsByDate(this.surrenderDetails.bank_account);
        if(this.surrenderDetails.eft_id && this.surrenderDetails.eft_id != 0){
            this.selectedBankAccount = this.bankAccount.filter(item => item.eft_id == this.surrenderDetails.eft_id)
        } else {
            this.selectedBankAccount = this.bankAccount
        }
        this.splitBankaccount(this.selectedBankAccount[0]);
        this.BankAccount = this.selectedBankAccount[0].id;
    }

    if(this.surrenderDetails.distribution_code !== "2-IRS Exception Distribution"){
      this.distribution_code =this.surrenderDetails.distribution_code;
    }
  
    
    // Form variable value assign
    this.first_name = (this.surrenderDetails.payee_firstname)?(this.surrenderDetails.payee_firstname):"";
    this.last_name = this.surrenderDetails.payee_lastname?this.surrenderDetails.payee_lastname:"";
    this.address_line1 = this.surrenderDetails.payee_address1?this.surrenderDetails.payee_address1:"";
    this.address_line2 = this.surrenderDetails.payee_address2?this.surrenderDetails.payee_address2:"";
    this.address_line3 = this.surrenderDetails.payee_address3?this.surrenderDetails.payee_address3:"";
    this.city = this.surrenderDetails.payee_city?this.surrenderDetails.payee_city:"";
    this.state = this.surrenderDetails.payee_state?this.surrenderDetails.payee_state:"";
    this.postal_code = this.surrenderDetails.payee_zip?this.surrenderDetails.payee_zip.replace(/(\d{5}(?!\d?$))\-?/g, '$1-'):"";
    this.country = this.surrenderDetails.payee_country?this.surrenderDetails.payee_country:"";
        
    this.role=(this.surrenderDetails && this.surrenderDetails.roles)?(this.surrenderDetails.roles):null;
    this.eft_status=(this.surrenderDetails && this.surrenderDetails.eft_status)?(this.surrenderDetails.eft_status):null;
    this.bank_account_verified=this.isBankAccountVerified?this.isBankAccountVerified:"";

    if(this.withdrawlSurrenderType != 'SYSTEMATIC_WITHDRAWL') {
        this.account_type=this.account_type?this.account_type:"";
        this.eft_id=(this.surrenderDetails && this.surrenderDetails.eft_id)?(this.surrenderDetails.eft_id) : 0;
    }
    
  }
  }

  sortBankAccountsByDate = (bankAccounts: any) => {
    return bankAccounts.sort((a: any, b: any) => {
        const dateA = new Date(a.split(' - ').pop()).getTime();
        const dateB = new Date(b.split(' - ').pop()).getTime();
        return dateB - dateA; 
    }).map((account: any) => {
        const [bank_name, account_type, account_number, transit, dateString,eft_id] = account.split(' - ');
        return {
            id: account,
            title: account,
            bank_name,
            account_type,
            account_number,
            transit,
            date: new Date(dateString),
            eft_id
        };
    });
}

  setDistributionCode(e) {
    let distributionCode = this.surrenderDetails.distribution_code;
    var federalWithholdingPer;
    if(e.includes('%')){
      e = e.slice(0, -1);
      federalWithholdingPer = Number(e);
    } else {
      federalWithholdingPer = e;
    }
    
    if(!this.isLife && (distributionCode === "2-IRS Exception Distribution" &&  federalWithholdingPer>20.00)){
      this.distribution_code = "2-IRS Exception Distribution";
    } else if(!this.isLife && (distributionCode === "2-IRS Exception Distribution" &&  federalWithholdingPer<=20.00)){
      this.distribution_code = "";
    } else{
      this.distribution_code = this.surrenderDetails.distribution_code;
    }
  }


  withdrawalChange(e){
  if(e=='D'){
    OcInfraModule.AppInjector.get(CommonService).updateWithdrawalChngeStatus(true);
  }
  else{
    OcInfraModule.AppInjector.get(CommonService).updateWithdrawalChngeStatus(false);
  }
  
  }

  isStepComplete() {
    if(this.withdrawlSurrenderType === 'DIVIDEND_WITHDRAWAL' || this.withdrawlSurrenderType === 'FULL_SURRENDER' || this.withdrawlSurrenderType === 'PARTIAL_SURRENDER' || this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL'){
      let stepComplete=this.checkRequiredFields();
      return stepComplete == 'Y' ? true : false;
    }
    // let stepComplete = this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL' ? this.checkRequiredFieldsforSysmtematicWithdrawal() : (this.checkRequiredFields());
    //return stepComplete == 'Y' ? true : false;
  }

  getPaymentValues() {
    if(this.withdrawlSurrenderType === 'DIVIDEND_WITHDRAWAL'){
    return {
      withdraw_to_pay_premium: [this.withdrawToPayPremiumM.toString()],
      disbursement_ind: [this.disbursementIndM.toString()],
      withdrawToPayPremiumM: this.withdrawToPayPremiumM,
      first_name: this.first_name,
      last_name: this.last_name,
      address_line1: this.address_line1,
      address_line2: this.address_line2,
      address_line3: this.address_line3,
      city: this.city,
      state: this.state,
      postal_code: this.postal_code,
      country: this.country,
      role: this.role,
      eft_status: this.eft_status,
      account_type: this.account_type,
      bank_account: this.bank_account,
      bank_name: this.bank_name,
      account_number: this.account_number,
      transit: this.transit,
      eft_two_premium_payments_verified: this.eft_two_premium_payments_verified,
      bank_account_verified: this.bank_account_verified,
      required_payment_fields:this.checkRequiredFields(),
      eft_id:this.eft_id
    };
  }
  if(this.withdrawlSurrenderType === 'FULL_SURRENDER' || this.withdrawlSurrenderType === 'PARTIAL_SURRENDER' || this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL') {
    if(this.disbursementIndM === 'C'){
    return {
      disbursement_ind: [this.disbursementIndM.toString()],
      distribution_code: this.distribution_code,
      first_name: this.first_name,
      last_name: this.last_name,
      address_line1: this.address_line1,
      address_line2: this.address_line2,
      address_line3: this.address_line3,
      city: this.city,
      state: this.state,
      postal_code: this.postal_code,
      country: this.country,
      required_payment_fields:this.checkRequiredFields()
    };
  }
    if(this.disbursementIndM==='E'){
      return {
        disbursement_ind: [this.disbursementIndM.toString()],
        distribution_code: this.distribution_code,
        bankAccount :([this.BankAccount.toString()])?([this.BankAccount.toString()]):"",
        role: this.role,
        eft_status: this.eft_status,
        account_type: this.account_type ? this.account_type.split('-')[0] : '',
        bank_name: this.bank_name,
        account_number: this.account_number,
        transit: this.transit,
        bank_account_verified: this.isBankAccountVerified,
        required_payment_fields:this.checkRequiredFields(),
        eft_id: this.eft_id,
      };
    }
  }
  }

    checkRequiredFields() {
        if (this.disbursementIndM == 'C') {
            return 'Y';
        } else if (this.withdrawlSurrenderType === 'DIVIDEND_WITHDRAWAL' && this.disbursementIndM == 'E' && this.eft_two_premium_payments_verified && this.bank_account_verified) {
            return 'Y';
        } else if (this.withdrawlSurrenderType === 'FULL_SURRENDER' && this.disbursementIndM == 'E' && this.isBankAccountVerified && this.BankAccount) {
            return 'Y';
        } else if (this.withdrawlSurrenderType === 'PARTIAL_SURRENDER' && this.disbursementIndM == 'E' && this.isBankAccountVerified && this.BankAccount) {
            return 'Y';
        } else if (this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL' && this.disbursementIndM == 'E'  && this.BankAccount) {
            return 'Y';
        } else {
            return 'N';
        }
    }



  checkRequiredFieldsforSysmtematicWithdrawal() {
    return this.surrenderPaymentMetod ? 'Y' : 'N';
  }
  splitBankaccount(data: any){
    this.bank_name = data.bank_name;
    this.account_number = data.account_number;
    this.transit = data.transit;
    let accountType = data.account_type;
    this.account_type = this.responseOptions.properties.account_type.oneOf.filter(x=>x.enum == accountType)[0].title;
    this.eft_id = data.eft_id;
  }
  onBankAccountChange(event: any){
    const bankData = this.bankAccount.find((item: any) => item.id == event.value);
    this.splitBankaccount(bankData);
  }
}
