import { Component, Input, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import * as quoteConstant from '../quote-loan-initiation/quote-loan-initiation.constant';
import { switchMap, filter, toArray, map } from 'rxjs/internal/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-payment-loan-initiation',
    templateUrl: './payment-loan-initiation.component.html',
    styleUrls: ['./payment-loan-initiation.component.scss'],
})
export class PaymentLoanInitiationComponent implements OnInit {
    @Input() loanInitiationPaymentData: quoteConstant.LoanInitiation;
    paymentForm: FormGroup;

    cashFormFields: quoteConstant.DynamicFormFieldModel[];
    eftFormFields!: quoteConstant.DynamicFormFieldModel[];
    formFields!: quoteConstant.DynamicFormFieldModel[];
    withholdList = quoteConstant.withholdList;
    disbursementList = quoteConstant.disbursementList;

    withhold_premium_indicator: string = 'N';
    disbursementValue: string = 'C';
    bankAccounts: any;
    bankAccount: string;
    bankAccountValue: any;
    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.cashFormFields = <quoteConstant.DynamicFormFieldModel[]>(
            quoteConstant.cashFormFields
        );
        this.eftFormFields = <quoteConstant.DynamicFormFieldModel[]>(
            quoteConstant.eftFormFields
        );
        this.infoPaymentFormInit();
        this.getDisbursementList();
        this.getWithholdList();
        this.changeDisbursement();
        this.getBankAccountList();
    }

    getDisbursementList = () => {
        const disbursementAll =
            this.loanInitiationPaymentData._options.properties.disbursementInd.oneOf;
        const disbursementInd = this.loanInitiationPaymentData.disbursementInd;
        const disbursementList = of(disbursementAll);
        disbursementList
            .pipe(
                switchMap((elements) => elements),
                filter((element: any) => {
                    return disbursementInd.includes(element.enum[0]);
                }),
                map((element) => ({
                    label: `${element.title}`,
                    value: `${element.enum[0]}`,
                })),
                toArray()
            )
            .subscribe((elements) => (this.disbursementList = elements));
    };

    getWithholdList = () => {
        const withholdPremiumAll =
            this.loanInitiationPaymentData._options.properties
                .withhold_premium_indicator.oneOf;
        this.withhold_premium_indicator =
            this.loanInitiationPaymentData.withhold_premium_indicator;

        of(withholdPremiumAll)
            .pipe(
                switchMap((elements) => elements),
                map((element) => ({
                    label: `${element.title}`,
                    value: `${element.enum[0]}`,
                })),
                toArray()
            )
            .subscribe((elements) => (this.withholdList = elements));
    };

    getBankAccountList = () => {
        this.bankAccounts = this.loanInitiationPaymentData["bank_accounts"]?this.loanInitiationPaymentData["bank_accounts"]:""; 
    }

    infoPaymentFormInit = () => {
        this.bankAccountValue = this.loanInitiationPaymentData["bank_accounts"]?this.loanInitiationPaymentData["bank_accounts"][0].bank_account:"";
        this.paymentForm = this.fb.group({
            withhold_premium_indicator: [this.withhold_premium_indicator],
            disbursementInd: [this.disbursementValue],
            bankAccount: [this.bankAccountValue],
        });
    }

    get disbursementInd() {
        return this.paymentForm.get('disbursementInd').value;
    }

    set disbursementInd(val) {
        this.paymentForm.get('disbursementInd').setValue(val);
    }

    changeDisbursement = () => {
        if (this.disbursementInd === 'C') {
            this.eftFormFields.forEach((formItem) => {
                this.paymentForm.removeControl(formItem.id);
            });

            this.cashFormFields.forEach((formItem) => {
                formItem.value = this.loanInitiationPaymentData[formItem.id];

                const formControl = this.fb.control(
                    formItem.value,
                    formItem.validators
                );
                this.paymentForm.addControl(formItem.id, formControl);
            });
            this.formFields = [...this.cashFormFields];
        } else {
            if(this.disbursementInd === 'E'){
            var bankAccountSelected = this.loanInitiationPaymentData["bank_accounts"][0];
            this.eftFormFields.forEach((formItem) => {
                formItem.value = bankAccountSelected[formItem.id];
                if(formItem.id === "role") {
                formItem.value = formItem.id==="role"?this.loanInitiationPaymentData.role:"";
                }
                const formControl = this.fb.control(
                    formItem.value,
                    formItem.validators
                );
                this.paymentForm.addControl(formItem.id, formControl);
            });

            this.formFields = [...this.eftFormFields];
        }
    }
    }

    changeBankAccount = (e) => {
        var bankAccountSelected = this.loanInitiationPaymentData["bank_accounts"].filter(x=>x.bank_account === e.value);
        this.eftFormFields.forEach((formItem) => {
            formItem.value = bankAccountSelected[0][formItem.id];
            if(formItem.id === "role"){
                formItem.value = this.loanInitiationPaymentData.role ? this.loanInitiationPaymentData.role :"";
            }

            this.paymentForm.get(formItem.id).setValue(formItem.value)
        });
    }

    onChangeEvent = ($event: any) => {
        console.log('onChangeEvent $event');
    }

    onKeypressEvent = ($event: any) => {
        console.log('onKeypressEvent $event');
    }
}
