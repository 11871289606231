import { Component, OnInit, ElementRef } from '@angular/core';
import { CommonService } from './../../services/common.service';
import { AuthenticationService } from './../../services/authentication.service';
import { OcInfraModule, Configuration, NavigationService, StoreService, APICallerService, IAlias, ResourceService, ResourceDirectoryService } from '@diaas/ux-web';;
import { Router, NavigationEnd } from '@angular/router';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { PartySearchFactory } from './../../factories/partySearchFactory';
import { MenuComponent } from "../customMenu.component/customMenu.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public visible: Boolean;
  public pageTitle: string;
  public olsTitle: boolean = false;
  public tittle: any = {
    "/screen/home": "Customer service Portal - Home",
    "/screen/policyDetails": "Customer service Portal - Policy",
    "/screen/agentSearch": " Ols Customer service Portal - search",
    "/screen/agentDetails": "Ols Customer service Portal - Agent",
    "/screen/partySearch": "Customer service Portal - search",
    "/screen/customerDetails": "Ols Customer service Portal - Customer",
    "/screen/olsPolicyDetails": "Ols Customer service Portal - Policy Details"
  }
  public olsHomeTitle: any = "Ols Customer service Portal - Home"
  public text: string;
  public isOlsRoute = false;
  private partySearchFactory = new PartySearchFactory();
  constructor(private commonService: CommonService, private authenticationService: AuthenticationService, private router: Router, public elementRef: ElementRef) { }

  ngOnInit() {
    localStorage.clear();

    this.authenticationService.getHeaderProperties
      .subscribe(response => {
        this.setVisble(response)
      });
    this.handleSearchBar(this.elementRef);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        OcInfraModule.AppInjector.get(CommonService).currentRoute = event.url;
        this.pageTitle = this.tittle[event.url];
        if (event.url === "/screen/olsPolicyDetails") {
          this.isOlsRoute = true;
        } else {
          this.isOlsRoute = false;
        }
        if (event.url === "/screen/home") {
          OcInfraModule.AppInjector.get(CommonService).olsUser ? this.pageTitle = this.olsHomeTitle : '';
          OcInfraModule.AppInjector.get(CommonService).aSearchValue = '';
          OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        }
      }
    });
    this.versionNumber();
  }

  private versionNumber() {
    this.commonService.get('/assets/images/changelog.html').subscribe(res => {
      var element = document.createElement('html');
      element.innerHTML = res._body;
      const header = element.getElementsByTagName('h2');
      // console.log(header);
      let splitDate = header[0].innerText.split(" ");
      const _date = new Date(splitDate[1].slice(1, -1));
      let dateFormat = (_date.getMonth() + 1) + '/' + _date.getDate() + '/' + _date.getFullYear();
      this.text = splitDate[0] + " (" + dateFormat + ")";
    });
  }


  private setVisble(isVisble) {
    this.visible = isVisble;
  }

  /**
   * Navigate to logout page if existrs
   */
  goToLogout(): void {
    let resource: any = JSON.parse(window.sessionStorage.getItem("resourceDirectory"));
    if (resource) {
      const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
      resource.forEach(alias => {
        resourceDirectoryService.deleteElementResourceDirectory(alias.href);
      });
    }
    OcInfraModule.AppInjector.get(StoreService).clearSession();
    OcInfraModule.AppInjector.get(NavigationService).navigateTo(Configuration.config.logoutPage);
  }

  /**
 * Navigate to search page
 */
  goToHome(): void {
    OcInfraModule.AppInjector.get(CommonService).total_remit_due = '';
    // Billing Tab - Reinstatement quote check box disabled
    OcInfraModule.AppInjector.get(CommonService).disableReinstatementCheckbox();
    OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
    OcInfraModule.AppInjector.get(CommonService).changeHeaders({ remote_user: null, company_code: null, region_code: null });
    OcInfraModule.AppInjector.get(CommonService).myCallHistory();
    OcInfraModule.AppInjector.get(CommonService).financialClick = false;
    OcInfraModule.AppInjector.get(CommonService).financialClickId = '';
    OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = [];
    OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
    OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/home');
  }

  goBack(): void {
    let route = OcInfraModule.AppInjector.get(CommonService).aobVisible ? '/screen/agentDetails' : '/screen/customerDetails';
    this.router.navigate([route]);
  }

  handleSearchBar(elementRef) {
    document.addEventListener('keydown', function (e) {
      // Removing localstorage of searchValue
      if (e.target["classList"].contains("csr-search-bar-input") && e.keyCode != 13) {
        localStorage.removeItem('searchValue');
      }
    })
    document.addEventListener('keyup', function (e) {
      if (e.target["classList"].contains("formatPostalCode")) {
        if (e.target["value"].length > 5 && e.target["value"].length < 10) {
          OcInfraModule.AppInjector.get(CommonService).postalCodeLength = true;
          e.target["minLength"] = 10
        }
        else {
          OcInfraModule.AppInjector.get(CommonService).postalCodeLength = false;
          e.target["minLength"] = 5;
        }
      }
      if (e.target["classList"].contains("formatChckAmountMaxLength")) {
        if (!isNaN(e.target["value"]) && e.target["value"].toString().indexOf('.') != -1) {
          e.target["maxLength"] = 21
        } else {
          e.target["maxLength"] = 20
        }
        if(!isNaN(e.target["value"]) && e.target["value"].split(".").length>1 && e.target["value"].split(".")[1].length>2 ){
          e.target["value"]=  e.target["value"].slice(0, -1) ;
        }
      }
    });
    document.addEventListener('paste', function (e) {
      let pastedText = e.clipboardData.getData('text');
      if (e.target["classList"].contains("formatChckAmountMaxLength")
        || e.target["classList"].contains("formatCurrency"))
       {
        setTimeout(function () {
          if (isNaN(pastedText as any) ||( !/^\d*(\.\d{1,2})+$/.test(pastedText)&&  !/^\d*$/.test(pastedText))){
            e.target["value"] = "";
            return false;
          }
          if(!isNaN(pastedText as any)){
            if (pastedText.toString().indexOf('.') != -1) {
              e.target["maxLength"] = 21
            } else {
              e.target["maxLength"] = 20
            }
          }
        }, 0);
      }
      if (e.target["classList"].contains("formatPhoneNumber")) {
        if (( pastedText.length == 10 ) && /^[0-9]+$/.test(pastedText)) {
          let formattedPhneNum = pastedText.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
          e.target["value"] = formattedPhneNum;
          return true;
        }
        else{
          e.preventDefault();
        }
      }
    });
    document.addEventListener('keypress', function (e) {
      if (e.target["classList"].contains("csr-search-bar-input") && e.keyCode != 13) {
        localStorage.removeItem('searchValue');
      }
      if (e.target["classList"].contains("formatPhoneNumber") || e.target["classList"].contains("formatPostalCode")
        || e.target["classList"].contains("formatCurrency")) {
        if (e.target["classList"].contains("formatCurrency")) {
          if (e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 46 || e.keyCode == 190) {
            if (e.keyCode == 9) {
              let val = event.target["value"].replace(/,/g, "");
              event.target["value"] = Number(val).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            }else  if((e.keyCode == 46 ||e.keyCode == 190) &&  event.target["value"].includes(".")){
              e.preventDefault();
              return false;
            }
          } else if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
            e.preventDefault();
          }
        } else if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
          e.preventDefault();
        } else {
          if (e.target["classList"].contains("formatPhoneNumber")) {
            e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
            return true;
          } else if (e.target["classList"].contains("formatPostalCode")) {
            e.target["value"] = e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
            return true;
          }
        }
      }
    }, false);
    document.addEventListener('blur', function (e) {
      if (e.target["classList"].contains("formatCurrency") &&
         (  e.target["value"].length >0 && !isNaN(e.target["value"]))) {
        var value = parseFloat(e.target["value"]);
        e.target["value"] = value.toFixed(2);
        e.preventDefault();
        return true;
      }
    },true);
    document.addEventListener('click', function (e) {
      if (e.target["parentElement"]["classList"].contains("surrender-modal") && e.target["id"] == "modal__cross") {
        if (OcInfraModule.AppInjector.get(CommonService).dividendAlert) {
          e.stopPropagation();
          let content = ""
          if(OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderType && OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderType === 'DIVIDEND_WITHDRAWAL'){
            content = 'Are you sure?  You have unsaved changes in the Dividend Withdrawal Window. Do you wish to close?';
          } else if(OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderType === 'FULL_SURRENDER'){
            content = 'Are you sure?  You have unsaved changes in the Full Surrender Window. Do you wish to close?';
          } else if(OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderType === 'SYSTEMATIC_WITHDRAWL'){
            content = 'Are you sure?  You have unsaved changes in the Systematic Withdrawal Window. Do you wish to close?';
          } else{
            content = 'Are you sure?  You have unsaved changes in the Partial Surrender Window. Do you wish to close?';
          }  
          let title = 'Alert';
          let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px')
          dialogRef.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse) {
              OcInfraModule.AppInjector.get(CommonService).dividendAlert = false;
              document.getElementById("modal__cross").click();
            }
          })
        } 
      }

      if (e.target["parentElement"]["classList"].contains("loan-initiation") && e.target["id"] == "modal__cross") {
        let loanInitiationFlag = OcInfraModule.AppInjector.get(CommonService).loanInitiation;
        if (loanInitiationFlag) {
          e.stopPropagation();
          let content = 'Are you sure?  You have unsaved changes in the Loan Initiation Window. Do you wish to close?';
          let title = 'Alert';
          let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px')
          dialogRef.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse) {
              OcInfraModule.AppInjector.get(CommonService).loanInitiation = false;
              document.getElementById("modal__cross").click();
            }
          })
        }
      }

      if (e.target["classList"].contains("mat-checkbox-inner-container")) {
        e.stopPropagation();
      }

      if (e.target["parentElement"]["classList"].contains("customProcessButton") && e.target["id"] == "modal__cross") {        
          OcInfraModule.AppInjector.get(CommonService).isAttachDoc = false; 
          document.getElementById("modal__cross").click();
      }

      let financialClick = OcInfraModule.AppInjector.get(CommonService).financialClick;
      let financialClickId = OcInfraModule.AppInjector.get(CommonService).financialClickId;
      if((e.target['innerText'].trim() == 'FINANCIAL HISTORY' || e.target['parentElement']['innerText'].trim() == 'FINANCIAL HISTORY') && !financialClick && financialClickId != '') {
        
        document.getElementById(financialClickId).click();
        OcInfraModule.AppInjector.get(CommonService).financialClick = true;
      }

      if (e.target["parentElement"]["classList"].contains("loan-initiation") && e.target["id"] == "modal__cross") {
        let loanInitiationFlag = OcInfraModule.AppInjector.get(CommonService).loanInitiation;
        if (loanInitiationFlag) {
          e.stopPropagation();
          let content = 'Are you sure?  You have unsaved changes in the Loan Initiation Window. Do you wish to close?';
          let title = 'Alert';
          let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px')
          dialogRef.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse) {
              OcInfraModule.AppInjector.get(CommonService).loanInitiation = false;
              document.getElementById("modal__cross").click();
            }
          })
        }
      }
    }, true);


    document.addEventListener('click', function (e) {
      if (e.target["classList"].contains("csr-search-bar-icon")) {
        document.getElementById("btnfilter").click();
      }
      let associateSuccess = OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess;
      let benefitSuccess = OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess;
      if (!OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess && e.target["parentElement"]["classList"].contains("create-claim-pop-up") && e.target["id"] == "modal__cross") {
        e.stopPropagation();
        let content = ' Please associate a policy before proceeding';
        let title = 'Alert';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'OK', '', title, '340px')
        dialogRef.afterClosed().subscribe(dialogResponse => {
        })
      }
      else if (!OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess && e.target["parentElement"]["classList"].contains("create-claim-pop-up") && e.target["id"] == "modal__cross") {
        e.stopPropagation();
        let claimPartyInformation = OcInfraModule.AppInjector.get(CommonService).claimPartyInformation;
        let content = ' Please perform Benefit Quote before exiting the claim Policy Number ';
        for(let i=0; i<claimPartyInformation.length; i++) {
          if(i == claimPartyInformation.length-1){
            content += claimPartyInformation[i].policyNumber;
          } else{
            content += claimPartyInformation[i].policyNumber+',';
          }
        }
        let title = 'Alert';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'OK', '', title, '340px')
        dialogRef.afterClosed().subscribe(dialogResponse => {
        })
      }
      if (!OcInfraModule.AppInjector.get(CommonService).createClaim && associateSuccess && benefitSuccess && e.target["parentElement"]["classList"].contains("create-claim-pop-up") && e.target["id"] == "modal__cross") {
        if (OcInfraModule.AppInjector.get(CommonService).claimAlert) {
          e.stopPropagation();
          let content = 'Are you sure? Do you wish to Continue?';
          let title = 'Alert';
          let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px')
          dialogRef.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse) {
              OcInfraModule.AppInjector.get(CommonService).claimAlert = false;
              document.getElementById("modal__cross").click();
            }
          })
        }
      }
      if (e.target["classList"].contains("mat-checkbox-inner-container")) {
        e.stopPropagation();
      }
      if (e.target["id"] == "address-edit-billing-anchor") {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        var isMFADone = OcInfraModule.AppInjector.get(CommonService).mfaAuthorized;
        if(!isMFADone && !policyDetails.data.product_type.startsWith("A")){
          e.stopPropagation();
          OcInfraModule.AppInjector.get(CommonService).selectedScreen = "billingupdate";
          document.getElementById("mfa-mfa-modal-anchor").click();
        }
      }
      if ( e.target["parentElement"]["classList"].contains("mfa-modal") && e.target["id"] == "modal__cross") {
        if(!OcInfraModule.AppInjector.get(CommonService).mfaAuthorized){
          OcInfraModule.AppInjector.get(CommonService).isMfaClosedManuallyWithoutVerification = true;
          let mfaMessageHtml = '<dl class="pad-lAr-10px">' + '<dt class="pull-left">MFA Pin Not Provided</dt><dd><br></dd>' + '</dl>';
          OcInfraModule.AppInjector.get(CommonService).mfaCallNotesMessage = "MFA Pin Not Provided";
          OcInfraModule.AppInjector.get(CommonService).mfaMessageHtml = mfaMessageHtml;
          OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        }
        if(OcInfraModule.AppInjector.get(CommonService).selectedScreenAction && !OcInfraModule.AppInjector.get(CommonService).mfaAuthorized){
          let mymenuObj = new MenuComponent(elementRef);
          var data = OcInfraModule.AppInjector.get(CommonService).selectedScreenAction;
          mymenuObj.onActionItemClick(data);
        }
      }
      if ( e.target["parentElement"]["classList"].contains("partyInfoPopup") && e.target["id"] == "modal__cross") {
        if( OcInfraModule.AppInjector.get(CommonService).roleEmailChanged ||
            OcInfraModule.AppInjector.get(CommonService).rolePhoneChanged ||
            OcInfraModule.AppInjector.get(CommonService).roleAddressUpdated ){
          e.stopPropagation();
          let content = 'You have unsaved Changes. Do you wish to continue closing?';
          let title = 'Alert - Unsaved Changes'
          let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title,'340px')
          dialogRef.afterClosed().subscribe(dialogResponse => {
          if(dialogResponse){
            OcInfraModule.AppInjector.get(CommonService).roleEmailChanged = false;
            OcInfraModule.AppInjector.get(CommonService).rolePhoneChanged = false;
            OcInfraModule.AppInjector.get(CommonService).roleAddressUpdated = false;
            document.getElementById("modal__cross").click();
          }})
        }
      }
    }, true);

    document.addEventListener('mousemove', function () {
      let billingAmount = document.getElementById("workitems-schema-billing-amount-text") ? document.getElementById("workitems-schema-billing-amount-text")["value"] : undefined;
      if (billingAmount) {
        let val = billingAmount.replace(/,/g, "");
        document.getElementById("workitems-schema-billing-amount-text")["value"] = Number(val).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
    }, false);

    document.addEventListener('click', (e) => {
        this.handlePolicyDetails(e);
      }, true);
  }

  IsAuthorized(): Boolean {
    return OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  }

  private handlePolicyDetails(e: MouseEvent) {
    const targetRow = (e.target as Element).closest('.mat-row') as HTMLTableRowElement;
    if (targetRow && (targetRow.closest('#searchResult') as HTMLElement)) {
      const rowIndex = Array.from(targetRow.parentElement.children).indexOf(targetRow);

      const cells = targetRow.cells;
      const rowData = {
        policy_no: cells[0].textContent.trim(),
        clientId: cells[1].textContent.trim(),
        name: cells[2].textContent.trim(),
        role: cells[3].textContent.trim(),
        birth_date: cells[4].textContent.trim(),
        tin: cells[5].textContent.trim(),
        address: cells[6].textContent.trim(),
        product_type: cells[7].textContent.trim()
      };

    const alias = OcInfraModule.AppInjector.get(ResourceService).getAliasByAliasName('searchContracts');
    const dataSource = OcInfraModule.AppInjector.get(ResourceDirectoryService).getResource(alias.href, alias.params);
    let params;
    if(rowData.clientId != '') {
      if(rowData.product_type == 'H') {
        params = dataSource.response._links.item.find(item => item.summary.role == rowData.role && item.summary.client == rowData.clientId )  
      } else {
        params = dataSource.response._links.item.find(item => item.summary.policy_no == rowData.policy_no && item.summary.role == rowData.role && item.summary.client == rowData.clientId)
      }
    } else {
        params = dataSource.response._links.item.find(item => item.summary.policy_no == rowData.policy_no && item.summary.role == rowData.role)
    }
    this.partySearchFactory.openPolicyDetails(params);
    }


  }
  

}