import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { CommonService, retryOperation } from '../../../services/common.service';
import { ItemAssetAccount, ItemProducts, OneOf, OneOfData, RMDDetails, TrxLevelIndOption } from './RMDTab.constant';
import * as moment from 'moment';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
    selector: 'app-rmd-tab',
    templateUrl: './RMDTab.component.html',
    styleUrls: ['./RMDTab.component.scss'],
})
export class RMDTabComponent implements OnInit {

    @Input() rmdDetails: RMDDetails;
    @Output() getRMDDetailsEmit = new EventEmitter<Object>();

    hostContractAlert: any;
    productValue: string = '';
    assetAccountValue: string = '';
    get isHostContractAlert(): any {
        return this.hostContractAlert;
    }
    @Input() set isHostContractAlert(value: any) {
        this.hostContractAlert = value;
        (this.hostContractAlert) && this.disableFormControls()   
    }

    mrpsContractAlert: any;
    get isMrpsContractAlert(): any {
        return this.mrpsContractAlert;
    }
    @Input() set isMrpsContractAlert(value: any) {
        this.mrpsContractAlert = value;
        (this.mrpsContractAlert) && this.disableFormControls() 
    }


    rmdForm: FormGroup;

    policyDetails: any;

    transactionalLevel: any[] = [];
    rmdMode: any[] = [];
    rmdDay: any[] = [];
    rmdRules: any[] = [];
    rmdType: any[] = [];
    percentOverrideBasics: any[] = [];
    current_date = new Date();

    isGroupAnnuity: boolean = false;

    clientProducts: ItemProducts[];
    clientProductItems: any;
    clientProductsUrl: string = '';

    assetAccounts: ItemAssetAccount[];
    assetAccountItems: any;
    assetAccountsUrl: string = '';

    retryDelay = 1000;
    retryAttempts = 3;

    headers: any;
    rmdHref: string = '';

    isGroupProduct: boolean = false;
    isHostContract: boolean = false;
    isMrpsContract: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private commonService: CommonService
    ) { }

    ngOnInit() {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        this.isHostContract = this.policyDetails.response.isHostContract;
        this.isMrpsContract = this.policyDetails.response.isMrpsContract;
        this.isGroupProduct = this.isGroupProductType();
        this.rmdFormInit();
    }

    ngAfterViewInit() {
        this.headers = this.commonService.getHeaderByAliasName("rmdDetails");
        if(this.isGroupProduct) {
            this.processGroupProduct();
        }
    }

    initGroupFormControls() {
        if (this.isGroupProduct) {
            this.rmdForm.addControl("productType", new FormControl(this.productValue));
            this.rmdForm.addControl("accountType", new FormControl(this.assetAccountValue));
        }
    }


    ngOnChanges(changes: SimpleChanges) {
        if(changes.rmdDetails) {
            this.rmdFormInit();
        }
        if (changes.rmdDetails && changes.rmdDetails.currentValue) {
            this.rmdDetails = changes.rmdDetails.currentValue;
            this.loadRmdDetails();
        }
    }

    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'response', 'product_type');
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            return true;
        } else {
            return false;
        }
    }

    rmdFormInit() {
        let end_date = moment('12/31/9999'); 
            var federalPercentValue = this.formatPercent('', 'blur', '0.00');
            var federalCurrencyValue = this.formatCurrency('', 'blur', '0.00');
            var statePercentValue = this.formatPercent('', 'blur', '0.00');
            var stateCurrencyValue = this.formatCurrency('', 'blur', '0.00');           
        let effective_date = this.getNested(this.policyDetails, 'response', 'aart_date');

        this.rmdForm = this.formBuilder.group({
            effective_date: [moment(effective_date), [Validators.required]],
            start_date: [this.current_date, [Validators.required]],
            end_date: [end_date, [Validators.required]],
            next_rmd_date: [this.current_date, [Validators.required]],

            transaction_level: ['', [Validators.required]],
            rmd_mode: ['', [Validators.required]],
            rmd_type: ['M', [Validators.required]],

            rmd_day: ['', [Validators.required]],
            rmd_rules: ['T', [Validators.required]],

            no_federal_withholding: [false],
            federal_withholding_override: [false],
            federal_withholding_amount: [federalCurrencyValue],
            federal_withholding_percentage: [federalPercentValue],

            no_state_withholding: [false],
            state_withholding_override: [false],
            state_withholding_amount: [stateCurrencyValue],
            state_withholding_percentage: [statePercentValue],

            percent_override_basics: [{value: ' ', disabled: true}]
        });

        this.initGroupFormControls();
    }


    get federal_withholding_amount() {
        return this.rmdForm.get('federal_withholding_amount').value;
    }

    set federal_withholding_amount(val) {
        this.rmdForm.get('federal_withholding_amount').setValue(val);
    }

    get federal_withholding_percentage() {
        return this.rmdForm.get('federal_withholding_percentage').value;
    }

    set federal_withholding_percentage(val) {
        this.rmdForm.get('federal_withholding_percentage').setValue(val);
    }

    get state_withholding_amount() {
        return this.rmdForm.get('state_withholding_amount').value;
    }

    set state_withholding_amount(val) {
        this.rmdForm.get('state_withholding_amount').setValue(val);
    }

    get state_withholding_percentage() {
        return this.rmdForm.get('state_withholding_percentage').value;
    }

    set state_withholding_percentage(val) {
        this.rmdForm.get('state_withholding_percentage').setValue(val);
    }


    // integrates the API response 
    loadRmdDetails() {

        this.loadTransactionalLevel();
        this.loadRmdMode();
        this.loadRmdType();
        this.loadRmdDay();
        this.loadRmdRules();
        this.loadPercentOverrideBasics();


        if (this.rmdDetails.effective_date != undefined) {
            this.rmdForm.get('effective_date').setValue(new Date(this.rmdDetails.effective_date));
            this.rmdForm.get('effective_date').disable();
        }

        if (this.rmdDetails.mrd_initial_start_date != undefined) {
            this.rmdForm.get('start_date').setValue(new Date(this.rmdDetails.mrd_initial_start_date));
        } else {
            this.rmdForm.get('start_date').setValue(null);
        }

        if (this.rmdDetails.next_payout_date != undefined) {
            this.rmdForm.get('next_rmd_date').setValue(new Date(this.rmdDetails.next_payout_date));
        } else {
            this.rmdForm.get('next_rmd_date').setValue(null);   
        }

        if (this.rmdDetails.mrd_payout_end_date != undefined) {
            this.rmdForm.get('end_date').setValue(new Date(this.rmdDetails.mrd_payout_end_date));
        } else {
            this.rmdForm.get('end_date').setValue(null);
        }

        if (this.rmdDetails.trx_level_ind != undefined) {
            this.rmdForm.get('transaction_level').setValue(this.rmdDetails.trx_level_ind);
        }

        if (this.rmdDetails.rmd_mode != undefined) {
            this.rmdForm.get('rmd_mode').setValue(this.rmdDetails.rmd_mode);
        }

        if (this.rmdDetails.rmd_type != undefined) {
            this.rmdForm.get('rmd_type').setValue(this.rmdDetails.rmd_type);
        }

        if (this.rmdDetails.rmd_day != undefined) {
            this.rmdForm.get('rmd_day').setValue(this.rmdDetails.rmd_day);
        }

        if (this.rmdDetails.mrd_rules != undefined) {
            this.rmdForm.get('rmd_rules').setValue(this.rmdDetails.mrd_rules);
        }

        this.rmdForm.get('federal_withholding_amount').disable();
        this.rmdForm.get('federal_withholding_percentage').disable();

        this.rmdForm.get('state_withholding_amount').disable();
        this.rmdForm.get('state_withholding_percentage').disable();
        let federalPercentValue = this.formatPercent('', 'blur', this.rmdDetails.wthldPctFed);
        let federalCurrencyValue = this.formatCurrency('', 'blur', this.rmdDetails.wthldAmtFed);
        let statePercentValue = this.formatPercent('', 'blur', this.rmdDetails.wthldPctSt);
        let stateCurrencyValue = this.formatCurrency('', 'blur', this.rmdDetails.wthldAmtSt);
        this.rmdForm.get('federal_withholding_amount').setValue(federalCurrencyValue);
        this.rmdForm.get('federal_withholding_percentage').setValue(federalPercentValue);
        this.rmdForm.get('state_withholding_amount').setValue(stateCurrencyValue);
        this.rmdForm.get('state_withholding_percentage').setValue(statePercentValue)
        if (this.rmdDetails.state_pctOr_basis != undefined && this.rmdDetails.state_pctOr_basis != '') {
            this.rmdForm.get('percent_override_basics').setValue('A');
        }
        if (this.isHostContractAlert || this.isMrpsContractAlert) {
            this.disableFormControls();
        }
    }

    disableFormControls = () => {
        //3420 change to not disable product and asset account
        let arr = Object.keys(this.rmdForm.controls);
        arr = arr.filter(x=>x != "productType" && x != "accountType");
        // Disable all form controls
        arr.forEach(key => {
            this.rmdForm.controls[key].disable();
        });
    }

    loadTransactionalLevel = () => {
        let transactionalLevel = this.rmdDetails.trx_level_ind_options;
        if (transactionalLevel && transactionalLevel.length > 0) {
            this.transactionalLevel = transactionalLevel.map((ele: TrxLevelIndOption): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadRmdMode = () => {
        let rmdMode = this.getNested(this.rmdDetails._options.properties, 'rmd_mode');
        if (rmdMode && rmdMode.oneOf.length > 0) {
            this.rmdMode = rmdMode.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadRmdType = () => {
        let rmdType = this.getNested(this.rmdDetails._options.properties, 'rmd_type');
        if (rmdType && rmdType.oneOf.length > 0) {
            this.rmdType = rmdType.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadRmdDay = () => {
        let rmdDay = this.getNested(this.rmdDetails._options.properties, 'rmd_day');
        if (rmdDay && rmdDay.oneOf.length > 0) {
            this.rmdDay = rmdDay.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadRmdRules = () => {
        let rmdRules = this.getNested(this.rmdDetails._options.properties, 'rmd_rules');
        if (rmdRules && rmdRules.oneOf.length > 0) {
            this.rmdRules = rmdRules.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadPercentOverrideBasics = () => {
        let percentOverrideBasics = this.getNested(this.rmdDetails._options.properties, 'state_pctOr_basis');
        if (percentOverrideBasics && percentOverrideBasics.oneOf.length > 0) {
            this.percentOverrideBasics = percentOverrideBasics.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    isStepComplete = () => {
        return this.rmdForm.valid;
    }



    noFederalEvent = ($event) => {
        if ($event.checked) {
            this.rmdForm.get('federal_withholding_override').disable();
            this.rmdForm.get('federal_withholding_amount').disable();
            this.rmdForm.get('federal_withholding_percentage').disable();
            let federalPercentValue = this.formatPercent('', 'blur', '0.00');
            let federalCurrencyValue = this.formatCurrency('', 'blur', '0.00');
            this.rmdForm.get('federal_withholding_amount').setValue(federalCurrencyValue);
            this.rmdForm.get('federal_withholding_percentage').setValue(federalPercentValue);
        } else {
            this.rmdForm.get('federal_withholding_override').enable();
        }
    };

    noStateEvent = ($event) => {
        if ($event.checked) {
            this.rmdForm.get('state_withholding_override').disable();
            this.rmdForm.get('state_withholding_amount').disable();
            this.rmdForm.get('state_withholding_percentage').disable();
            let statePercentValue = this.formatPercent('', 'blur', '0.00');
            let stateCurrencyValue = this.formatCurrency('', 'blur', '0.00'); 
            this.rmdForm.get('state_withholding_amount').setValue(stateCurrencyValue);
            this.rmdForm.get('state_withholding_percentage').setValue(statePercentValue)
            this.rmdForm.get('percent_override_basics').disable();
            this.rmdForm.get('percent_override_basics').setValue(' ');
        } else {
            this.rmdForm.get('state_withholding_override').enable();
            this.rmdForm.get('percent_override_basics').disable();
            this.rmdForm.get('percent_override_basics').setValue(' ');
        }
    };

    federalOverrideEvent = ($event: any) => {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')

        if ($event.checked) {
            this.rmdForm.get('federal_withholding_amount').enable();
            this.rmdForm.get('federal_withholding_percentage').enable();

            this.rmdForm.get('no_federal_withholding').disable();

        } else {
            this.federal_withholding_amount = currencyValue;
            this.federal_withholding_percentage = percentValue;

            this.rmdForm.get('federal_withholding_amount').disable();
            this.rmdForm.get('federal_withholding_percentage').disable();

            this.rmdForm.get('no_federal_withholding').enable();
        }
    }

    stateOverrideEvent = ($event: any) => {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')

        if ($event.checked) {
            this.rmdForm.get('state_withholding_amount').enable();
            this.rmdForm.get('state_withholding_percentage').enable();

            this.rmdForm.get('no_state_withholding').disable();

            this.rmdForm.get('percent_override_basics').enable();
            this.rmdForm.get('percent_override_basics').setValue('A');
        } else {
            this.state_withholding_amount = currencyValue;
            this.state_withholding_percentage = percentValue;

            this.rmdForm.get('state_withholding_amount').disable();
            this.rmdForm.get('state_withholding_percentage').disable();

            this.rmdForm.get('no_state_withholding').enable();
            this.rmdForm.get('percent_override_basics').disable();
            this.rmdForm.get('percent_override_basics').setValue(' ');
        }
    }


    federalWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.federal_withholding_amount = this.formatCurrency('', 'blur', this.federal_withholding_amount);
            this.rmdForm.get('federal_withholding_percentage').disable();
            let percentValue = this.formatPercent('', 'blur', '0.00'); //PANOCSR-3429 When we are entering amount, percent should be disabled and blank or vice versa for SWP and RMD
            this.federal_withholding_percentage = percentValue;
        }

        if (type == 'PERCENT') {
            this.federal_withholding_percentage = this.formatPercent('', 'blur', this.federal_withholding_percentage);
            this.rmdForm.get('federal_withholding_amount').disable();
            let currencyValue = this.formatCurrency('', 'blur', '0.00') //PANOCSR-3429 When we are entering percent, amount should be disabled and blank or vice versa for SWP and RMD
            this.federal_withholding_amount = currencyValue;
            this.percentageCheck(this.federal_withholding_percentage);
        }
    };

    stateWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.state_withholding_amount = this.formatCurrency('', 'blur', this.state_withholding_amount);
            this.rmdForm.get('state_withholding_percentage').disable();
            let percentValue = this.formatPercent('', 'blur', '0.00');
            this.state_withholding_percentage = percentValue;
        }

        if (type == 'PERCENT') {
            this.state_withholding_percentage = this.formatPercent('', 'blur', this.state_withholding_percentage);
            this.rmdForm.get('state_withholding_amount').disable();
            let currencyValue = this.formatCurrency('', 'blur', '0.00')
            this.state_withholding_amount = currencyValue;
            this.percentageCheck(this.state_withholding_percentage);
        }
    };


    // To Pass the ser selected values to the master component
    getRmdValues = (): any => {

        let state_withholding_amount = this.rmdForm.get('state_withholding_amount').value;
        let state_withholding_percentage = this.rmdForm.get('state_withholding_percentage').value;

        let federal_withholding_amount = this.rmdForm.get('federal_withholding_amount').value;
        let federal_withholding_percentage = this.rmdForm.get('federal_withholding_percentage').value;

        let rmd_day: OneOfData;
        if (this.rmdDay && this.rmdDay.length > 0) {
            rmd_day = this.rmdDay.find((item: OneOfData) => item.value == this.rmdForm.get('rmd_day').value);
        }

        let rmd_type: OneOfData;
        if (this.rmdType && this.rmdType.length > 0) {
            rmd_type = this.rmdType.find((item: OneOfData) => item.value == this.rmdForm.get('rmd_type').value);
        }

        let rmd_rules: OneOfData;
        if (this.rmdRules && this.rmdRules.length > 0) {
            rmd_rules = this.rmdRules.find((item: OneOfData) => item.value == this.rmdForm.get('rmd_rules').value);
        }

        let trx_level_ind: OneOfData;
        if (this.transactionalLevel && this.transactionalLevel.length > 0) {
            trx_level_ind = this.transactionalLevel.find((item: OneOfData) => item.value == this.rmdForm.get('transaction_level').value);
        }

        let rmd_mode: OneOfData;
        if (this.rmdMode && this.rmdMode.length > 0) {
            rmd_mode = this.rmdMode.find((item: OneOfData) => item.value == this.rmdForm.get('rmd_mode').value);
        }

        let surrenderValues: any = {
            effective_date: moment(this.policyDetails.data.aart_date).format('MM/DD/yyyy'),

            mrd_initial_start_date: moment(this.rmdForm.get('start_date').value).format('MM/DD/yyyy'),
            next_payout_date: moment(this.rmdForm.get('next_rmd_date').value).format('MM/DD/yyyy'),
            end_date: moment(this.rmdForm.get('end_date').value).format('MM/DD/yyyy'),

            trx_level_ind: trx_level_ind && trx_level_ind.value,
            rmd_mode: rmd_mode && rmd_mode.value,
            business_closehour: this.rmdDetails.business_closehour,

            rmd_type: rmd_type && rmd_type.value,
            rmd_day: rmd_day && rmd_day.value,
            rmd_rules: rmd_rules && rmd_rules.value,

            no_federal_withholding: this.rmdForm.get('no_federal_withholding').value,
            no_state_withholding: this.rmdForm.get('no_state_withholding').value,

            fed_Wthor_ind: this.rmdForm.get('federal_withholding_override').value,
            wthldPctFed: federal_withholding_percentage,
            wthldAmtFed: federal_withholding_amount,

            state_wthor_ind: this.rmdForm.get('state_withholding_override').value,
            wthldPctSt: state_withholding_percentage,
            wthldAmtSt: state_withholding_amount,

            state_pctOr_basis: this.rmdForm.get('percent_override_basics').value,

            rmd_day_label: rmd_day && rmd_day.label,
            rmd_type_label: rmd_type && rmd_type.label,
            rmd_mode_label: rmd_mode && rmd_mode.label,
            rmd_rules_label: rmd_rules && rmd_rules.label,
            trx_level_ind_label: trx_level_ind && trx_level_ind.label,
        };
        return surrenderValues;
    }

    percentageCheck(value){
        let totalWithPer = value.includes('%') ? value.slice(0,-1) : value;
        let totalInt = Math.trunc(totalWithPer);
        let finalVal = totalInt.toString();
        if(finalVal.length>2){
            let alertMsg = [];
            alertMsg.push("You can not enter more than 2 digits");
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        }
    }


    processGroupProduct = async () => {
        this.clientProductsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.assetAccountsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;

        await Promise.all([
            this.getClientProductsPromise(),
            this.getAssetAccountsPromise()
        ]);
        const product = this.clientProducts[0].summary.plan_code;
        this.getAssetAccountforLoad(product);
        this.getRMDDetailsEmit.emit(this.headers);
    }

    getClientProductsPromise = async () => {
        await retryOperation(this.getClientProducts, this.retryDelay, this.retryAttempts)
            .then((res) => {
                this.commonService.clientProducts = res;
                this.clientProducts = res;
                this.processProductTypes(this.clientProducts);
            })
            .catch((err) => this.getClientProductsCall());
    }

    getClientProducts = async () => {
        let clientProducts = await this.commonService.getClientProducts();
        if (clientProducts) {
            return Promise.resolve(clientProducts);
        } else {
            return Promise.reject('No data');
        }
    }

    getClientProductsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.clientProductsUrl, this.headers).subscribe((response: any) => {
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).clientProducts = response._links.item;
                this.clientProducts = response._links.item;
            }
        }, (error: any) => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }


    processProductTypes = (clientProducts: ItemProducts[]) => {
        if (clientProducts.length > 0) {
            this.clientProductItems = clientProducts.map((item: ItemProducts) => {
                return { label: item.summary.plan_code, value: item.summary.plan_code }
            });
            // this.clientProductItems.unshift({label:'ALL PRODUCTS', value:'AP'});      
        }
    }

    //PANOCSR-3428, all asset accounts disabled
    getAssetAccountforLoad = (value) => {

        this.productValue = value;
        this.assetAccountValue = 'ALL ASSET ACCOUNTS'
        this.rmdForm.get('productType').setValue(this.productValue);
        this.rmdForm.get('accountType').setValue(this.assetAccountValue);

        let productCodes = this.clientProducts.find(item=> item.summary.plan_code==value)
        this.headers.plan_id = productCodes.summary.plan_code;
        let assetAccounts = this.assetAccounts.find((item: ItemAssetAccount) => item.summary.plan_code == productCodes.summary.plan_code);
        this.headers.client_id = this.commonService.productInfo.client;
        this.headers.asset_account = assetAccounts.summary.asset_account[0];
        this.headers.product_selection = productCodes.summary.plan_code;
    }
       
    getAssetAccountsPromise = async () => {
        await retryOperation(this.getAssetAccounts, this.retryDelay, this.retryAttempts)
            .then((res) => {
                this.commonService.clientAssetAccountsAll = res;
                this.assetAccounts = res;
            })
            .catch((err) => this.getAssetAccountsCall());
    }

    getAssetAccounts = async () => {
        let assetAccounts = await this.commonService.getClientAssetAccountsAll();
        if (assetAccounts) {
            return Promise.resolve(assetAccounts);
        } else {
            return Promise.reject('No data');
        }
    }

    getAssetAccountsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.assetAccountsUrl, this.headers).subscribe((response: any) => {
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).clientAssetAccountsAll = response._links.item;
                this.assetAccounts = response._links.item;
            }
        }, (error: any) => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    productTypeChange = (e: any) => {

        this.productValue = e.value;
        this.rmdForm.get('productType').setValue(this.productValue);
        this.assetAccountValue = 'ALL ASSET ACCOUNTS'
        this.rmdForm.get('accountType').setValue(this.assetAccountValue);

        let productCodes = this.clientProducts.find(item=> item.summary.plan_code==e.value)
        this.headers.plan_id = productCodes.summary.plan_code;
        let assetAccounts = this.assetAccounts.find((item: ItemAssetAccount) => item.summary.plan_code == productCodes.summary.plan_code);
        this.headers.client_id = this.commonService.productInfo.client;
        this.headers.asset_account = assetAccounts.summary.asset_account[0];
        this.headers.product_selection = productCodes.summary.plan_code;
        this.getRMDDetailsEmit.emit(this.headers);
    }

    accountTypeChange = (e: any) => {
        this.rmdForm.get('accountType').setValue(e.value);

        let plan_code = this.rmdForm.controls.productType.value;
        this.headers.plan_id = plan_code;
        this.headers.client_id = this.commonService.productInfo.client;
        this.headers.asset_account = e.value.split('-')[0].trim();
        this.getRMDDetailsEmit.emit(this.headers);
    }

    getRMDDetails(effective_date?: string) {
        this.rmdHref = this.policyDetails && this.policyDetails._links.rmd.href;
        if (!this.rmdHref) return;

        let params = { quoteEffectiveDate: effective_date ? effective_date : this.rmdDetails.effective_date };
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.rmdHref, this.headers, params).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            this.rmdDetails = response;
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    ngOnDestroy() {

    }



    //formats the value with commas and $ allowing 2 decimal values
    formatCurrency(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        }
        if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'currency', true);
            right_side = this.formatNumber(right_side, 'currency', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = input_val.substring(0, 15);
            input_val = this.formatNumber(input_val, 'currency', true);
            input_val = "$" + input_val;
            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    // formats the value with commas and percentage allowing 1 decimal value
    formatPercent(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        } if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'percent', true);
            right_side = this.formatNumber(right_side, 'percent', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = left_side + "." + right_side + "%";
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val, 'percent', true);
            if (blur === "blur") {
                input_val += ".00";
            }
            input_val = input_val + "%";
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }


    /** AMOUNT AND PERCENT FORMATING  */
    formatNumber(n, type, flag) {
        if (flag) {
            n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
        }
        if (type == 'currency') {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }



}
