import { Component, OnDestroy, OnInit } from '@angular/core';
import { APICallerService, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { IncomingMoneyData, incomingMoneycolumnsDef } from './incoming-money.constant';
import { CurrencyPipe } from '@angular/common';
import { CommonService, retryOperation } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { NextPrevAction, Pagination } from '../custom-table/custom-table.constant';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { FinancialHistoryDisplayComponent } from '../financial-history-display/financial-history-display.component';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';

@Component({
    selector: 'app-incoming-money',
    templateUrl: './incoming-money.component.html',
    styleUrls: ['./incoming-money.component.scss']
})
export class IncomingMoneyComponent implements OnInit, OnDestroy {

    incomingMoneyData: IncomingMoneyData[] = [];
    incomingMoneyResponse: any;
    customClass = 'incoming-money';
    columnsDef: any[];
    retryDelay = 3000;
    retryAttempts = 7;
    nextprev: NextPrevAction = { nextBlock: false, prevBlock: false };
    filterParams: any = {};
    isdisabledPagination: boolean = false;
    pagination: Pagination = {
        disabled: false
    }
    defaultSort = 'effective_date';
    financialFilterSubscription$: Subscription;
    policyDetails: any;

    retryDelayP = 3500;
    retryAttemptsP = 7;
    initCall: boolean = false;
    
    constructor(private currencyPipe: CurrencyPipe, private commonService: CommonService, public dialog: MatDialog) {
        this.financialFilterSubscription$ = this.commonService.getFinancialHistoryFilterEvent().subscribe((alias) => {
            this.filterParams = this.commonService.financialHistoryRes;
            if (alias == "incomingmoney") {
                this.applyFilter(alias, this.filterParams)
            }
        });
    }

    ngOnInit() {
        this.columnsDef = incomingMoneycolumnsDef(this.currencyPipe);
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        this.getPolicyDetailsPromise();
    }

    getPolicyDetailsPromise = async () => {
        await retryOperation(this.getPolicyDetails, this.retryDelayP, this.retryAttemptsP)
            .then((res) => {
                this.getIncomingMoneyData();
            })
            .catch((err) => { });
    };

    getPolicyDetails = () => {
        let policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (policyDetails) {
            return Promise.resolve(policyDetails);
        } else {
            return Promise.reject('No data');
        }
    }

    getIncomingMoneyData = async () => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let [url, params] = this.commonService.getHrefUrlWithParams(policyDetails._links['incomingmoney'].href);
        let optionParams = new OptionParams();
        optionParams.alias = "incomingmoney";        
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("incomingmoney");
        optionParams.params = params;
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(async response => {
            this.initCall = true;
            this.mapDataToTable(response);             
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });   
        }, async (err:any) => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        });       
    }

    getIncomingMoneyDetails = () => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let response = resourceService.getResourceFromAliasName('incomingmoney');
        if (response) {
            return Promise.resolve(response);
        } else {
            return Promise.reject('No data');
        }
    }

    nextPrevAction = (response: any) => {
        this.nextprev.prevBlock = (response._links.previous) ? true : false;
        this.nextprev.nextBlock = (response._links.next) ? true : false;
    }

    mapDataToTable = (response: any) => {
        this.nextPrevAction(response);
        this.incomingMoneyResponse = response;
        if (response && response._links && response._links.item) {
            let data = response._links.item;
            this.incomingMoneyData = data.map(item => {
                if (item.summary == 'amount') {
                    return this.currencyPipe.transform(item.summary.amount);
                }
                return item.summary;
            });
        }
    }

    applyFilter = async (alias: string, optionParams: any) => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let hrefUrl = resourceService.getHrefByAliasName(alias);
        let params = {};
        if (this.filterParams != undefined && Object.keys(this.filterParams).length >= 0 && 'params' in this.filterParams) {
            params = this.commonService.transformParams(this.filterParams['params'])
        }
        this.apiIncomingMoney(hrefUrl, optionParams.headers, params);
    }

    nextprevCall = ($event: any) => {
        let optionParams = new OptionParams();
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("incomingmoney");
        let hrefUrl = this.incomingMoneyResponse._links[$event].href;
        let params = {};
        if (this.filterParams != undefined && Object.keys(this.filterParams).length >= 0 && 'params' in this.filterParams) {
            params = this.commonService.transformParams(this.filterParams['params']);
            delete params['history_type'];
        }
        this.apiIncomingMoney(hrefUrl, optionParams.headers, params);
    }

    apiIncomingMoney = async (hrefUrl: string, headers, params: any) => {
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(CommonService).getCallback(hrefUrl, headers, params).subscribe(async response => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            this.mapDataToTable(response);
        }, async (error: any) => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                await OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }

        });
    }

    openHistoryDetails = ($event: any) => {
        // historyId=NA-KA0000142&history_type=payment_history
        let params = { historyId: $event.history_id, history_type: 'incoming_money'};
        let headers = { client_id: '', display_value: '', effective_date: ''};       
        let productInfo = this.commonService.productInfo;
        if (productInfo && (productInfo["product_type"] == "A3" || productInfo["product_type"] == "A4")) {
            headers = { client_id: this.policyDetails.response.client_id, display_value: 'PARTICIPANT', effective_date: $event.effective_date };  
        }
        if(this.filterParams && this.filterParams.headers && Object.keys(this.filterParams.headers).length > 0) {
            let displayValue = this.filterParams.params.find((ele: any) => ele.key === 'display_value')
            headers = { ...headers, ...{display_value: displayValue.value} };
        }

        let dialogRef = this.dialog.open(CustomDialogComponent, {
            width: '1000px',
            disableClose: false,
            data: { component: FinancialHistoryDisplayComponent, title: 'Incoming Money', params: params, headers: headers }
        });
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.financialFilterSubscription$.unsubscribe();
        }, 0);
    }
}

