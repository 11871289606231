import { Component, ElementRef } from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import * as _ from 'lodash';
import { WithdrawalSurrenderAction } from '../withdrawlsSurrender/withdrawalSurrenderAction';
import { PartySearchFactory } from '../../factories/partySearchFactory';
@Component({
    selector: 'app-menu',
    templateUrl: './customMenu.component.html',

})
export class MenuComponent {
    panels: object;
    actionListItems: any;
    withdrawlTypes = ["DIVIDEND_WITHDRAWAL", "FULL_SURRENDER", "SYSTEMATIC_WITHDRAWL", "PARTIAL_SURRENDER"];
    authorizedRolesForReports = ['agent', 'third party administrator'];
    policyDetails: any;
    partialEnabled: any;
    fullEnabled: any;
    withdrawalEnabled: any;
    isSystematicWithdrawlAllowed: any = false;
    isWithdrawalsEnabled: any;
    isNotAnnuity: boolean;
    isNotUL: boolean;
    isA2orA4: boolean;
    isMultiProductWithA4: any;
    isTerm: boolean;
    loanInitiationEnabled: boolean;
    planReportEnabled: boolean = false;
    RMDEnabled: boolean = false;
    isFundTransferEnabled: boolean = false;
    commonService = OcInfraModule.AppInjector.get(CommonService);
    authorizedRolesForRMD = ["owner", 'co-owner', "agent", "agent assistant", "boa back office assistant (advisor assistant)"];
    authorizedRolesForGroupRMD = ["owner", "agent", "agent assistant"];


    private partySearchFactory = new PartySearchFactory()
    authorizedRolesForFull = ["owner", 'co-owner', "agent", "agent assistant", "boa back office assistant (advisor assistant)"];
    authorizedRolesForFullLife = ["owner", "agent", "agent/advisor assistant"];
    authSuccess = false;
    otherRoleAuthsucess = false;
    isAnnuity: boolean;
    buttonClickAction:boolean = false;
    constructor(public elementRef: ElementRef) {
        this.panels = {
            "policyHistoryActions": [
                {
                    "id": "createWorkRequest",
                    "label": "History Option 1"
                },
                {
                    "id": "editWorkRequest",
                    "label": "History Option 2"
                },
            ],
            "workRequestHistoryActions": [
                {
                    "id": "createWorkRequest",
                    "label": "Work Request Option 1"
                },
                {
                    "id": "editWorkRequest",
                    "label": "Work Request Option 2"
                },
            ],
            "claimsActions": [
                {
                    "id": "createDeathClaim",
                    "label": "Create Death Claim",
                    "enable": this.enabled
                }
            ],
            "policyDetailsActions": [
                {
                    "id": "DIVIDEND_WITHDRAWAL",
                    "label": "Dividend Withdrawal",
                    "enable": this.withdrawalEnabled
                },
                {
                    "id": "LOAN_INITIATION",
                    "label": "Loan Initiation",
                    "enable": this.loanInitiationEnabled
                },
                {
                    "id": "FUND_TRANSACTIONS",
                    "label": "Fund Transactions",
                    "enable": this.isFundTransferEnabled
                },
                {
                    "id": "PLAN_REPORTING",
                    "label": "Plan Reporting",
                    "enable": this.planReportEnabled
                },
                {
                    "id": "FULL_SURRENDER",
                    "label": "Full Surrender",
                    "enable": this.fullEnabled
                },
                {
                    "id": "RMD",
                    "label": "RMD",
                    "enable": this.RMDEnabled
                },
                {
                    "id": "PARTIAL_SURRENDER",
                    "label": "Partial Surrender",
                    "enable": this.partialEnabled
                },
                {
                    "id": "SYSTEMATIC_WITHDRAWL",
                    "label": "Systematic Withdrawal",
                    "enable": this.isSystematicWithdrawlAllowed
                }
            ]

        }
        
        if (this.elementRef.nativeElement.id == 'claimsActions' && this.commonService.isDuplicatePoliciesAction()) {
            this.buttonClickAction = true;
        } else {
            this.buttonClickAction = false;
        }
    }
    public enabled: boolean;
    onActionItemClick(action) {
        Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
            if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias == 'createClaim') {
                headerObj.headers.role = null;
            }
        });
        if (action.id === "PLAN_REPORTING" || action.id === "FUND_TRANSACTIONS" || action.id == "createDeathClaim") {

            if (action.id === "PLAN_REPORTING") {
                document.getElementById("plan-report-plan-report-anchor").click();
            }
            if (action.id == "FUND_TRANSACTIONS") {
                // Launch Fund Transfer modal with all types ..
                // create user withdrawl flow type pendingTransactions or Ellipsis menu
                OcInfraModule.AppInjector.get(CommonService).clearWithdrawalSurrenderAction();
                var withdrawlAction: WithdrawalSurrenderAction = OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction();
                withdrawlAction.setWithdrawlType(action.id);
                document.getElementById("funds-transactions-create-funds-transaction-anchor").click();
            }
            if (action.id === "createDeathClaim") {
                OcInfraModule.AppInjector.get(CommonService).createClaim = false;
                OcInfraModule.AppInjector.get(CommonService).claimAlert = true;
                OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess = true;
                OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = true;
                let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
                const resourceService = OcInfraModule.AppInjector.get(ResourceService);
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                let claims = resourceService.getResourceFromAliasName('claimList');
                if (claims && claims._links && (claims._links.item).length) {
                    document.getElementById("create-claim-popup-show-anchor").click();
                } else {
                    document.getElementById("create-claim-create-claim-item-anchor").click();
                }
            }
        }
        else {
            if (OcInfraModule.AppInjector.get(CommonService).mfaAuthorized || OcInfraModule.AppInjector.get(CommonService).isMfaClosedManuallyWithoutVerification) {
                OcInfraModule.AppInjector.get(CommonService).isMfaClosedManuallyWithoutVerification = false;
                if (action.id === "LOAN_INITIATION") {
                    document.getElementById("loan-initiation-create-loan-initiation-anchor").click();
                    OcInfraModule.AppInjector.get(CommonService).loanInitiation = true;
                }
                if (action.id === "RMD") {
                    document.getElementById("rmd-rmd-modal-anchor").click();
                }
                if (this.withdrawlTypes.includes(action.id)) {
                    // create user withdrawl flow type pendingTransactions or Ellipsis menu
                    OcInfraModule.AppInjector.get(CommonService).clearWithdrawalSurrenderAction();
                    var withdrawlAction: WithdrawalSurrenderAction = OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction();
                    withdrawlAction.setWithdrawlType(this.withdrawlTypes.find(actionType => actionType == action.id));
                    if (action.id == "SYSTEMATIC_WITHDRAWL") {
                        if ((OcInfraModule.AppInjector.get(CommonService).mfaAuthorized)) {
                            document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
                        }
                    }
                    else {
                        document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
                    }
                    OcInfraModule.AppInjector.get(CommonService).dividendAlert = true;
                }
            } else {
                document.getElementById("mfa-mfa-modal-anchor").click();
                OcInfraModule.AppInjector.get(CommonService).selectedScreen = action.id;
                OcInfraModule.AppInjector.get(CommonService).selectedScreenAction = action;
            }
        }
    }

    onActionClick(event) {
        let productInfo = this.commonService.productInfo;
        let selectedActionId = event.currentTarget.parentElement.id;
        this.actionListItems = this.panels[selectedActionId];
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        const productType = productInfo ? productInfo["product_type"].toLowerCase() : '';

        this.authSuccess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        this.otherRoleAuthsucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
        let selectedRole = '';
        if (this.authSuccess) {
            selectedRole = this.getNested(selectedIdentity, 'data', 'role');
        }
        if (this.otherRoleAuthsucess) {
            selectedRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
        }

        if (productType && (productType == "h")) {
            this.planReportEnabled = (OcInfraModule.AppInjector.get(CommonService).authsucess && this.authorizedRolesForReports.includes(selectedIdentity.data.role.toLowerCase())) || (OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess && OcInfraModule.AppInjector.get(CommonService).otherRole != "Other");
            this.actionListItems[3].enable = this.planReportEnabled;
        } else {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            this.isNotAnnuity = (details.data.product_type.includes('A') ? false : true);
            this.isA2orA4 = ((details.data.product_type.includes('A2') || details.data.product_type.includes('A4')) ? true : false);
            if (details.data.product_type.includes('A3')) {
                let assetAccount = resourceService.getResourceFromAliasName('policyAssetAccounts');
                this.isMultiProductWithA4 = _.filter(assetAccount.response._links.item, function (o) { return o.summary.product_type == 'A4' && o.summary.multi_product == 'Yes' });
                this.isMultiProductWithA4.length > 0 ? this.isA2orA4 = true : this.isA2orA4 = false;
            }

            this.isTerm = (details.data.product_type.includes('T') ? true : false);
            this.isNotUL = (details.data.product_type.includes('U') ? false : true);
            this.withdrawalEnabled = ((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) && this.isNotAnnuity && this.isNotUL);
            this.loanInitiationEnabled = ((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) && this.isTerm);
            this.enabled = ((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess));
            this.isWithdrawalsEnabled = OcInfraModule.AppInjector.get(CommonService).disableTermandFia ? false : (OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess);
            this.isFundTransferEnabled = ((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) && this.isA2orA4);

            this.isAnnuity = details.data.product_type.includes('A') ? true : false;
            let roleForRMD = selectedRole ? this.authorizedRolesForRMD.includes(selectedRole.toLowerCase()) : false;
            let isGroupProduct = this.isGroupProductType(details);
            if (isGroupProduct) {
                roleForRMD = selectedRole ? this.authorizedRolesForGroupRMD.includes(selectedRole.toLowerCase()) : false;
            }
            let mrdIndicator = details.data.mrd_indicator == 'Y' ? true : false;
            this.RMDEnabled = (this.authSuccess || this.otherRoleAuthsucess) && roleForRMD && this.isAnnuity && mrdIndicator;
            if (!this.isNotAnnuity) {
                if (OcInfraModule.AppInjector.get(CommonService).authsucess && selectedIdentity.data.role.toLowerCase() === 'agent') {
                    this.planReportEnabled = true;
                } else {
                    if (OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess && OcInfraModule.AppInjector.get(CommonService).otherRole != "Other") {
                        const otherRole = OcInfraModule.AppInjector.get(CommonService).otherRoleSummary;
                        this.planReportEnabled = otherRole.groupNumber !== null && otherRole.groupNumber.length > 0;
                    } else if ((OcInfraModule.AppInjector.get(CommonService).authsucess)) {
                        this.planReportEnabled = this.authorizedRolesForReports.includes(selectedIdentity.data.role.toLowerCase()) && selectedIdentity.data.groupNumber != null && selectedIdentity.data.groupNumber.length > 0;
                    }
                }

            }
            if (selectedActionId == "policyDetailsActions") {
                this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') ? OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails').response : null;
                let policyLinks = this.policyDetails._links;
                if (this.policyDetails && policyLinks && this.isWithdrawalsEnabled) {
                    //Enabling FullSurrender in Ellipsis
                    let roleForFullSurrender = selectedRole ? this.authorizedRolesForFull.includes(selectedRole.toLowerCase()) : false;
                    let roleForFullSurrenderLife = selectedRole ? this.authorizedRolesForFullLife.includes(selectedRole.toLowerCase()) : false;


                    let isCv15Contract = this.policyDetails.isCv15Contract ? true : false;
                    let isLife = this.partySearchFactory.isTermProduct() || this.partySearchFactory.isUL();
                    let isMrpsContract = this.policyDetails.isMrpsContract ? true : false;
                    if (isCv15Contract || isMrpsContract) {
                        if (this.isAnnuity) {
                            this.fullEnabled = (this.authSuccess || this.otherRoleAuthsucess) && (roleForFullSurrender) && (this.isAnnuity) && (isCv15Contract || isMrpsContract) && (policyLinks["/surrender/full"] ? true : false);
                        } else {
                            this.fullEnabled = (this.authSuccess || this.otherRoleAuthsucess) && (isCv15Contract || isMrpsContract) && (policyLinks["/surrender/full"] ? true : false);
                        }
                    } else if (isLife) {
                        this.fullEnabled = (this.authSuccess || this.otherRoleAuthsucess) && (roleForFullSurrenderLife) && (isLife) && (policyLinks["/surrender/full"] ? true : false);
                    }

                    if (isCv15Contract) {
                        if (this.isAnnuity) {
                            this.partialEnabled = (this.authSuccess || this.otherRoleAuthsucess) && (roleForFullSurrender) && (this.isAnnuity) && (isCv15Contract) && (policyLinks["surrender/partial"] ? true : false);
                        } else {
                            this.partialEnabled = (this.authSuccess || this.otherRoleAuthsucess) && (isCv15Contract) && (policyLinks["surrender/partial"] ? true : false);
                        }
                    } else if (isLife) {
                        this.partialEnabled = (this.authSuccess || this.otherRoleAuthsucess) && (roleForFullSurrenderLife) && (isLife) && (policyLinks["surrender/partial"] ? true : false);
                    }

                    //let isMrpsContract = this.policyDetails.isMrpsContract ? true : false;
                    if (isMrpsContract) {
                        if (this.isAnnuity) {
                            this.partialEnabled = (this.authSuccess || this.otherRoleAuthsucess) && (roleForFullSurrender) && (this.isAnnuity) && (isMrpsContract) && (policyLinks["surrender/partial"] ? true : false);
                        } else {
                            this.partialEnabled = (this.authSuccess || this.otherRoleAuthsucess) && (isMrpsContract) && (policyLinks["surrender/partial"] ? true : false);
                        }
                    }

                    let isHostContract = this.policyDetails.isHostContract ? true : false;

                    // this.isSystematicWithdrawlAllowed = policyLinks.systematicWithdrawal ? true : false;
                    if (isCv15Contract) {
                        this.isSystematicWithdrawlAllowed = (this.authSuccess || this.otherRoleAuthsucess) && (isCv15Contract) && (policyLinks["surrender/systematic"] ? true : false);
                    } else if (isMrpsContract) {
                        this.isSystematicWithdrawlAllowed = (this.authSuccess || this.otherRoleAuthsucess) && (isMrpsContract) && (policyLinks["surrender/systematic"] ? true : false);
                    } else if (isHostContract) {
                        this.isSystematicWithdrawlAllowed = (this.authSuccess || this.otherRoleAuthsucess) && (isHostContract) && (policyLinks["surrender/systematic"] ? true : false);
                    }

                }

                this.actionListItems[0].enable = this.withdrawalEnabled;
                this.actionListItems[1].enable = this.loanInitiationEnabled;
                this.actionListItems[2].enable = this.isFundTransferEnabled;
                this.actionListItems[3].enable = this.planReportEnabled;
                this.actionListItems[4].enable = this.fullEnabled;
                this.actionListItems[5].enable = this.RMDEnabled;
                this.actionListItems[6].enable = this.partialEnabled;
                this.actionListItems[7].enable = this.isSystematicWithdrawlAllowed;



            } else {
                for (let i = 0; i < this.actionListItems.length; i++) {
                    this.actionListItems[i].enable = this.enabled;
                }
            }
        }
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    isGroupProductType = (policyDetails) => {
        let product_type = this.getNested(policyDetails, 'response', 'product_type');
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            return true;
        } else {
            return false;
        }
    }

}