import { Component, OnInit, ViewChild } from '@angular/core';
import { APICallerService, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { FinancialHistoryDisplayComponent } from '../financial-history-display/financial-history-display.component';
import { MatDialog } from '@angular/material';
import { CommonService, retryOperation } from '../../services/common.service';

export interface TableData {
    eff_date: string;
    payment_type: string;
    status: string;
    amount: string;
}

@Component({
    selector: 'app-annuityand-ulfunds',
    templateUrl: './annuityand-ulfunds.component.html',
    styleUrls: ['./annuityand-ulfunds.component.scss']
})

export class FundsComponent implements OnInit {
    displayedColumns: string[] = ['eff_date', 'payment_type', 'status', 'amount'];
    noRecords: boolean = true;
    FundsTabData: TableData[];
    fundsTableData: any;


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    nextBlock: boolean;
    previousBlock: boolean;
    FundsRes: any;
    filterParams: any;
    retryDelay = 3000;
    retryAttempts = 7;

  financialFilterSubscription$: Subscription;
  policyDetails: any;
  retryDelayP = 3500;
  retryAttemptsP = 7;
  initCall: boolean = false;

  constructor(public commonService: CommonService, public dialog: MatDialog) {
    this.commonService.getFinancialHistoryFilterEvent().subscribe((alias) => {
      this.filterParams = this.commonService.financialHistoryRes;
      if (alias == "historyFunds") {
        this.applyFilter(alias, this.filterParams)
      }
    });
  }

    ngOnInit() {
        this.FundsTabData = [];
        this.fundsTableData = new MatTableDataSource(this.FundsTabData);
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        this.getPolicyDetailsPromise();
    }

    getPolicyDetailsPromise = async () => {
        await retryOperation(this.getPolicyDetails, this.retryDelayP, this.retryAttemptsP)
            .then((res) => {
                this.getFundshistoryData();
            })
            .catch((err) => { });
    };

    getPolicyDetails = () => {
        let policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (policyDetails) {
            return Promise.resolve(policyDetails);
        } else {
            return Promise.reject('No data');
        }
    }

    getFundshistoryData = async () => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let [url, params] = this.commonService.getHrefUrlWithParams(policyDetails._links["financial_funds"].href);
        let optionParams = new OptionParams();
        optionParams.alias = "historyFunds";
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("historyFunds");
        optionParams.params = params;
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(async response => {
            this.initCall = true;
            this.getFundsDetails(response);
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }, async (err: any) => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        });
    }

    getFundsDetails(response) {
        if (response && response._links && response._links.item) {
            if (response._links.previous) {
                this.previousBlock = true;
            } else {
                this.previousBlock = false;
            }
            if (response._links.next) {
                this.nextBlock = true;
            } else {
                this.nextBlock = false;
            }
            this.FundsRes = response;
            this.FundsTabData = response._links.item;
            this.fundsTableData = new MatTableDataSource(this.FundsTabData);
            this.fundsTableData.paginator = this.paginator;
            this.sortData({ active: "eff_date", direction: "desc" });
        }
        this.sortedData = response._embedded ? [] : response._links.item;
        this.noRecords = !this.FundsTabData || !this.FundsTabData.length;
        this.fundsTableData.sort = this.sort;
    }

    getBlock(block) {
        let optionParams = new OptionParams();
        optionParams.headers = this.commonService.getHeaderByAliasName("historyFunds");
        this.FundsTabData = [];
        this.fundsTableData = new MatTableDataSource(this.FundsTabData);
        let hrefUrl = this.FundsRes._links[block].href;
        this.commonService.getCallback(hrefUrl, optionParams.headers).subscribe(response => {
            if (response && response._links && response._links.item) {
                if (response._links.previous) {
                    this.previousBlock = true;
                } else {
                    this.previousBlock = false;
                }
                if (response._links.next) {
                    this.nextBlock = true;
                } else {
                    this.nextBlock = false;
                }
                this.FundsRes = response;
                this.FundsTabData = response._links.item;
                this.fundsTableData = new MatTableDataSource(this.FundsTabData);
                this.fundsTableData.paginator = this.paginator;
                this.sortData({ active: "eff_date", direction: "desc" });
            }
            this.sortedData = response._embedded ? [] : response._links.item;
            this.noRecords = !this.FundsTabData || !this.FundsTabData.length;
        });
        this.fundsTableData.sort = this.sort;
    }


    setDataSourceAttributes() {
        if (this.fundsTableData) {
            this.fundsTableData.paginator = this.paginator;
            this.fundsTableData.sort = this.sort;
        }
    }

    sortedData: TableData[];
    sortData(sort) {
        const data = this.fundsTableData.filteredData.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }
        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'eff_date': return compare(new Date(a.summary.effective_date).getTime(), new Date(b.summary.effective_date).getTime(), isAsc);
                default: return 0;
            }
        });
        this.fundsTableData = new MatTableDataSource(this.sortedData);
    }


    applyFilter = async (alias: string, optionParams: any) => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let hrefUrl = resourceService.getHrefByAliasName(alias);
        let params = {};
        if (this.filterParams != undefined && Object.keys(this.filterParams).length >= 0 && 'params' in this.filterParams) {
            params = this.commonService.transformParams(this.filterParams['params'])
        }
        this.apiProgramEnrollment(hrefUrl, optionParams.headers, params);
    }

    apiProgramEnrollment = async (hrefUrl: string, headers, params: any) => {
        await this.commonService.showHideSpinner({ showSpinner: true, edit: false });
        this.commonService.getCallback(hrefUrl, headers, params).subscribe(async response => {
            this.getFundsDetails(response);
            await this.commonService.showHideSpinner({ showSpinner: false, edit: false });
        }, async error => {
            await this.commonService.showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    openHistoryDetails = ($event: any) => {
        let params = { historyId: $event.summary.history_id, history_type: 'funds' };
        let headers = { client_id: '', display_value: '', effective_date: '' };
        let productInfo = this.commonService.productInfo;
        if (productInfo && (productInfo["product_type"] == "A3" || productInfo["product_type"] == "A4")) {
            headers = { client_id: this.policyDetails.response.client_id, display_value: 'PARTICIPANT', effective_date: $event.summary.effective_date };
        }
        if (this.filterParams && this.filterParams.headers && Object.keys(this.filterParams.headers).length > 0) {
            let displayValue = this.filterParams.params.find((ele: any) => ele.key === 'display_value')
            headers = { ...headers, ...{ display_value: displayValue.value } };
        }
        let dialogRef = this.dialog.open(CustomDialogComponent, {
            width: '1000px',
            disableClose: false,
            data: { component: FinancialHistoryDisplayComponent, title: 'Funds', params: params, headers: headers }
        });
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.financialFilterSubscription$.unsubscribe();
        }, 0);
    }
}
function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}